import React, { useEffect } from 'react';
import { Card, Container, Row, Col, Button, Navbar } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowRight, ArrowLeft } from 'react-bootstrap-icons';
import logo from './Cavinkare_Logo_Updated.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

const links = {
    HR: [
      { name: 'CLR Application', url: 'https://hrmisprd.ckdigital.in/' },
      { name: 'CLMS', url: 'https://clms.ckdigital.in/' },
      {name: 'Cavinhub', url: 'https://cavinhub.darwinbox.in'},
      {name:'Time Office', url:'https://tos.cavinkare.in:85/frmLogin.aspx'},
      {name:'Goal Sync', url:'https://goalsync.in/'}
    ],
    Production: [
      { name: 'OEE Entry', url: 'https://oeeprd.ckdigital.in/'},
      { name: 'OEE Portal(Live)', url: 'https://prodmaxdev.ckdigital.in'},
      { name: 'CLIT', url: 'https://oeeprd.ckdigital.in/'},
      { name: 'Cleaning & Sanitization Record', url: 'https://oeeprd.ckdigital.in/'},
      { name: 'Hourly Data Form', url: 'https://oeeprd.ckdigital.in/'},
      { name: 'Batch Coding Verification', url: 'https://oeeprd.ckdigital.in/'},
      { name: 'Bulk Inward Register', url: 'https://oeeprd.ckdigital.in/'},
    ],
    Quality: [
      { name: 'AQL AOQL Record', url: 'https://oeeprd.ckdigital.in/'},
      { name: 'Quality Lab Application', url: 'https://qaprd.ckdigital.in/'},
      { name: 'GMP Audit Module', url: 'https://oeeprd.ckdigital.in/'},
      { name: 'QCFI Login', url: 'https://qcfi.in/qcfihq/'},
    ],
    Maintenance: [
      { name: 'Maintenance Order Creation', url: 'https://bkappprd.ckdigital.in/'},
      { name: 'Preventive Maintenance', url: 'https://bkappprd.ckdigital.in/'},
      { name: 'MTTR & MTBF', url: 'https://cavins.ckdigital.in:3000/login'},
      { name: 'EMS Portal', url: 'http://ems.cavinkare.in/'},
    ],
    Utilities: [
      { name: 'Flow link', url: 'http://upc.flowlinc.io/login'},
      { name: 'Uttarakhand Power Corporation Limited', url: 'https://www.upcl.org/wss/viewBill'},
    ],
    IT: [
      {name: 'Outlook - Mail', url:'https://outlook.office365.com/mail/'},
      {name: 'TATA lease line ', url:'https://mymrtg.tatatelebusiness.com/'},
      {name: 'CK Assets', url:'https://ckassets.cavinkare.in/'},

    ],
    Safety: [
      { name: 'NSC Login', url: 'https://connect.nsc.org.in/'},
      { name: 'Work Permit Dashboard', url: 'https://oeeprd.ckdigital.in/'},
      { name: 'Safety CheckList', url: 'https://oeeprd.ckdigital.in/'},
    ],
    Gate: [
      {name: 'Happy Visitor - Visitor entry software', url: 'https://vms.cavinkare.in:8443/visitor_management/login'},
    ],
    TicketingTool: [
      {name: 'Ticketing Tool', url: 'http://helpdesk.cavinkare.in'},
    ],
    VirtualAudit: [
      {name: 'Virtual Audit', url: 'https://vaaudit.ckplprojects.com/'},
    ],
    Compliance: [
      {name: 'Ground Water E&E Solution', url: 'http://customer.enggenv.com'},
      {name: 'Legal HR Software', url: 'https://cavinkare.komrisk.com/komrisk/'},
      {name: 'UK GOV E Challan', url: 'https://ifms.uk.gov.in/'},
      {name: 'Sidcul UK Gov Site', url: 'https://investuttarakhand.uk.gov.in/'},
      { name: 'CGWA Login', url: 'https://cgwa-noc.gov.in/ExternalLogin.aspx?ReturnUrl=%2fExternalUser%2fApplicantHome.aspx'},
    ]
    
  };

const DetailPage = () => {
    const navigate = useNavigate();
    const { category } = useParams();
    const categoryLinks = links[category];

    useEffect(() => {
      AOS.init({
        duration: 1200, 
        once: true,    
      });
    }, []);

  return (
    <div className="details-page">
      <Navbar variant="light" expand="lg" className="justify-content-between">
        <Navbar.Brand className="d-flex align-items-center">
        <Col xl={4} md={4} sm={12} lg={4}> <img
            height={65}
            src={logo}
            className="d-inline-block align-top"
            alt="Logo"
          />
          </Col>
          <Col className="d-flex justify-content-center" xl={4} md={4} sm={12} lg={4}>
          <h3 className="mb-0 mx-auto">CK Haridwar Digital Hub</h3>
          </Col>
          <Col className="d-flex justify-content-end" xl={4} md={4} sm={12} lg={4}>
          </Col>
        </Navbar.Brand>
      </Navbar>

      <Container className="mt-2">
        <Button onClick={() => navigate('/')}><ArrowLeft /> Back</Button>
        <div className="text-center mt-1 mb-3">
          <h3>{category}</h3>
        </div>
        <Row className="mt-4 justify-content-center">
          {categoryLinks.map((link, index) => (
            <Col key={index} xs={12} md={4} className="mb-4">
              <Card 
                onClick={() => window.open(link.url, '_blank')}
                data-aos="zoom-in-up" 
              >
                <Card.Body style={{ cursor: 'pointer' }}>
                  <Card.Title>{link.name}</Card.Title>
                  <ArrowRight className="float-right text-primary" style={{ fontSize: '1.5rem' }} />
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default DetailPage