import React, { useRef, useEffect } from 'react';
import { Card, Container, Row, Col, Navbar, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import bannerImage from '../components/CavinKare.png'; 
import logo from '../components/Cavinkare_Logo_Updated.png';

const categories = [
  'Production', 'Quality', 'Maintenance', 'Utilities', 'IT', 'HR', 'Safety', 'Gate', 'TicketingTool',
  'VirtualAudit', 'Compliance'
];

const HomePage = () => {
  const navigate = useNavigate();
  const scrollRef = useRef(null);

  useEffect(() => {
    AOS.init({
      duration: 1200, 
      once: true,    
    });
  }, []);

  const handleCardClick = (category) => {
    navigate(`/detail/${category}`);
  };

  const handleScrollToEnd = () => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <Navbar variant="light" expand="lg" className="justify-content-center">
        <Navbar.Brand className="d-flex align-items-center flex-grow-1 justify-content-center">
        <Col xl={4} md={4} sm={12} lg={4}><img
            height={65}
            src={logo}
            className="d-inline-block align-top"
            alt="Logo"
          />
          </Col>
          <Col className="d-flex justify-content-center" xl={4} md={4} sm={12} lg={4}>
          <h3 className="mb-0 mx-auto">CK Haridwar Digital Hub</h3>
          </Col>
          <Col className="d-flex justify-content-end" xl={4} md={4} sm={12} lg={4}>
          </Col>
        </Navbar.Brand>
      </Navbar>

      <Container className="mt-4 container">
        <Card className="p-4 banner-card">
          <Row className="justify-content-center align-items-center">
            <Col xs={12} md={7} className="text-center">
              <img src={bannerImage} alt="Banner" className="banner-image" />
              <div className="banner-image-overlay"></div>
            </Col>
            <Col xs={12} md={5} className="text-center">
              <h2 className="welcome-text">Welcome to CK Haridwar Digital Hub</h2>
              <p className="informative-text">
                Your one-stop solution for accessing various sections
                and tools within our organization.
              </p>
              <p className="informative-text">
                Explore the categories below to get detailed information
                and services provided by different Departments.
              </p>
              <Button variant="primary" onClick={handleScrollToEnd}>Explore Sections</Button>
            </Col>
          </Row>
        </Card>

        <div ref={scrollRef}>
          <Row className="mt-4 justify-content-center">
            {categories.map((category, index) => (
              <Col key={index} xs={12} md={4} className="mb-4">
                <Card 
                  onClick={() => handleCardClick(category)} 
                  className="position-relative category-card"
                  data-aos="zoom-in"
                >
                  <Card.Body>
                    <Card.Title>{category}</Card.Title>
                    <div className="card-overlay">
                      <span className="text-muted">Click to see more details</span>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default HomePage;
